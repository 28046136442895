/* Base Form Styles */

.form-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-group {
    position: relative;
    margin-bottom: 50px;
    width: 100%;
}

.inputs-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}


/* Input Field Styles */

.input-field {
    width: 100%;
    padding: 1.5rem;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    font-size: 1.2rem;
    color: #666;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.input-field::placeholder {
    color: #999;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.input-field.error {
    border-color: #ff0000;
}

.input-field:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}


/* Error Message Styles */

.error-message {
    position: absolute;
    bottom: -2.5rem;
    left: 0;
    width: 100%;
    color: #ff0000;
    font-size: 0.875rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    display: none;
}

.error-message.show {
    display: block;
}


/* Loading Spinner Styles */

.loading-container {
    position: relative;
}

.loading-spinner {
    position: absolute;
    right: 1rem;
    top: 1.5rem;
    width: 20px;
    height: 20px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #808080;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    display: none;
}

.loading-container.loading .loading-spinner {
    display: block;
}


/* Track Button Styles */

.verify {
    width: 280px;
    padding: 1.2rem;
    background: #808080;
    border: none;
    border-radius: 12px;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.verify:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}


/* Popup Container Styles */

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-container {
    background: white;
    border-radius: 12px;
    position: relative;
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
}


/* Popup Header Styles */

.popup-header {
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'VCR OSD Mono', monospace;
    background: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom: 1px solid #e0e0e0;
}

.popup-header h1 {
    margin: 0;
    font-size: 1.2rem;
    letter-spacing: 1px;
}

.close-popup {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    font-family: 'VCR OSD Mono', monospace;
    padding: 0.5rem;
}


/* Popup Content Styles */

.popup-content {
    padding: 1.5rem;
    overflow-y: auto;
    flex-grow: 1;
    width: 100%;
}


/* Scrollbar Styles */

.popup-content::-webkit-scrollbar {
    width: 8px;
}

.popup-content::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.popup-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}


/* Delivery List Styles */

.delivery-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.delivery-button {
    width: 100%;
    background: #f5f5f5;
    border: none;
    border-radius: 8px;
    padding: 1rem;
    cursor: pointer;
    text-align: left;
    font-family: 'VCR OSD Mono', monospace;
    transition: background-color 0.2s;
    position: relative;
    overflow: hidden;
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delivery-button:hover {
    background: #ebebeb;
}

.delivery-button:active {
    transform: translateY(1px);
    background: #e0e0e0;
}


/* Delivery Content Styles */

.delivery-button-content {
    flex-grow: 1;
    position: relative;
    transition: opacity 0.3s;
}

.delivery-credentials {
    font-size: 1rem;
    color: #333;
}

.delivery-expiry {
    font-size: 0.9rem;
    color: #666;
    margin-top: 0.5rem;
}


/* Status Icon Styles */

.status-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    font-size: 1.2rem;
    width: 24px;
}

.status-icon .check {
    color: #4ade80;
}

.status-icon .cross {
    color: #f87171;
}


/* Copy Notification Styles */

.copy-notification {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    font-size: 1rem;
    color: #666;
    animation: fadeInOut 2s ease-in-out forwards;
    z-index: 2;
}

.delivery-button.copied .delivery-button-content {
    opacity: 0;
}


/* Animations */

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.popup-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.copy-all-button {
    background: none;
    border: none;
    font-family: 'VCR OSD Mono', monospace;
    font-size: 1.5em;
    margin-right: 20px;
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copy-all-button:hover {
    color: #666;
}

.bottom-status {
    text-align: center;
    padding: 1rem;
    color: #666;
    font-family: 'VCR OSD Mono', monospace;
    font-size: 1rem;
    border-top: 1px solid #e0e0e0;
    margin-top: 1rem;
}


/* Add these new styles to your existing CSS */

.popup-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.copy-all-button {
    background: none;
    border: none;
    font-family: 'VCR OSD Mono', monospace;
    font-size: 1.5em;
    margin-right: 20px;
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copy-all-button:hover {
    color: #666;
}

.bottom-status {
    text-align: center;
    padding: 1rem;
    color: #666;
    font-family: 'VCR OSD Mono', monospace;
    font-size: 1rem;
    border-top: 1px solid #e0e0e0;
    margin-top: 1rem;
}


/* Update existing popup-header styles */

.popup-header h1 {
    margin: 0;
    font-size: 1.2rem;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.copy-all-button {
    font-size: 1em;
    /* Increase the size */
    margin-right: 20px;
    /* Add more space between buttons */
    padding: 5px 12px;
    /* Add padding for a larger button */
    cursor: pointer;
    align-items: center;
    justify-content: center;
}