/* Form Container */

.form-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}


/* Form Group */

.form-group {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    gap: 1rem;
}


/* Input Container - New wrapper */

.input-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}


/* Input Field */

.input-field {
    width: 100%;
    padding: 1.5rem;
    padding-right: 6rem;
    /* Increased padding to ensure text doesn't overlap */
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    font-size: 1.2rem;
    color: #666;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    /* Ensure padding is included in width */
}

.input-field::placeholder {
    color: #999;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.input-field:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

.input-field.verified {
    background: #f8f9fa;
    cursor: pointer;
    user-select: all;
    text-align: center;
    padding-right: 6rem;
    /* Maintain consistent padding when verified */
}

.input-field.error {
    border-color: #ff0000;
}


/* Reset Button */

.reset-button {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.5rem;
    z-index: 10;
    min-width: 4rem;
    background-color: white;
    /* Add background to ensure text doesn't show through */
}


/* Rest of the styles remain the same */

.button-container {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    justify-content: center;
}

.request-button,
.purchase,
.verify {
    width: 280px;
    padding: 1.2rem;
    background: #808080;
    border: none;
    border-radius: 12px;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.loading-container {
    position: relative;
    width: 100%;
}

.loading-spinner {
    position: absolute;
    right: 5.5rem;
    /* Adjusted to account for reset button */
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #808080;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    display: none;
}

.loading-container.loading .loading-spinner {
    display: block;
}

.error-message {
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    color: #ff0000;
    font-size: 0.875rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: none;
}

.error-message.show {
    display: block;
}

.copy-message {
    position: absolute;
    bottom: -1.5rem;
    left: 50%;
    transform: translateX(-50%);
    color: #28a745;
    font-size: 0.875rem;
    letter-spacing: 1px;
    display: none;
}

.copy-message.show {
    display: block;
    animation: fadeOut 2s forwards;
}

@keyframes spin {
    0% {
        transform: translateY(-50%) rotate(0deg);
    }
    100% {
        transform: translateY(-50%) rotate(360deg);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@media (max-width: 768px) {
    .menu-container {
        transform: translateX(-250px);
    }
    .menu-container.active {
        transform: translateX(0);
    }
    .form-container {
        padding: 0 1rem;
    }
    .button-container {
        flex-direction: column;
    }
    .request-button,
    .purchase,
    .verify {
        width: 100%;
    }
}