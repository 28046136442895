.logo-container {
    margin-bottom: 2rem;
    width: 300px;
    height: 300px;
}

.logo {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo img {
    max-width: 100%;
    height: 300px;
    width: 300px;
}