* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'VCR OSD Mono', sans-serif;
    /* Updated font name */
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* This centers content vertically */
    padding: 2rem;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    min-height: 100vh;
    /* This ensures full height */
}

@keyframes bounce {
    0%,
    100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}

.bouncing-logo {
    animation: bounce 2s infinite;
}