/* menu.css */

.menu-container {
    position: fixed;
    left: 0;
    width: 250px;
    height: 100vh;
    z-index: 50;
    transform: translateX(-250px);
    transition: transform 0.3s ease;
}

.menu-container.active {
    transform: translateX(0);
}

.menu {
    background: white;
    width: 100%;
    height: 100%;
    padding: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu-toggle {
    position: absolute;
    top: 1rem;
    left: auto;
    right: -40px;
    z-index: 100;
    background: none;
    border: none;
    padding-left: 10px;
    font-size: 1rem;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-toggle.active::before {
    content: "CLOSE";
}

.menu-toggle:not(.active)::before {
    content: "MENU";
}

.menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.menu li {
    margin: 0;
    margin-bottom: 1rem;
}

.menu a {
    text-decoration: none;
    font-size: 1.1rem;
    color: #333;
    font-family: 'VCR OSD Mono', monospace;
}

.menu .top {
    display: flex;
}

.menu .bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
}


/* Popup styles */

.popup {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    justify-content: center;
    align-items: center;
}

.popup-content {
    position: relative;
    background: #fff;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.popup-content iframe {
    width: 100%;
    height: 400px;
    border: none;
}