/* src/styles/popup.css */

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    position: relative;
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
}

.close-popup {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    font-family: 'VCR OSD Mono', monospace;
}

.popup-page {
    font-family: 'VCR OSD Mono', monospace;
}

.popup-page h1 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.popup-page p {
    margin-bottom: 1rem;
    line-height: 1.5;
}

.popup-page a {
    color: blue;
    text-decoration: underline;
}

.popup-page a:hover {
    text-decoration: none;
}